import React, { useState } from "react";
import dayjs from "dayjs";

// mui import
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid2 as Grid } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from "@mui/material/Tooltip";

// date-fns import
import format from "date-fns/format";
import endOfDay from "date-fns/endOfDay";

const DateRange = ({ dateObject, changeMethod }) => {
  const [errorText, setErrorText] = useState(null);

  const handleStartDateChange = async (e) => {
    setErrorText(null);
    let updatedDate = { start: e.$d, end: endOfDay(dateObject.end) };

    if (updatedDate.start > updatedDate.end) {
      setErrorText("La fecha de inicio es mayor que la fecha final");
    } else {
      changeMethod(updatedDate);
    }

  };

  const handleEndDateChange = (e) => {
    setErrorText(null);
    let updatedDate = { ...dateObject, end: endOfDay(e.$d) };

    if (updatedDate.start > updatedDate.end) {
      setErrorText("La fecha de inicio es mayor que la fecha final");
    } else {
      changeMethod(updatedDate);
    }

  };

  return (
    <Grid container size={12}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid size="auto" display="flex" alignItems="center" justifyContent="space-between" sx={{gap: 1}}>
      <DatePicker
            id="from"
            label="Inicio"
            value={dayjs(format(dateObject.start, "yyyy-MM-dd"))}
            onChange={handleStartDateChange}
            format="DD/MM/YYYY" // Set the desired format
            slotProps={{ textField: { size: "small", variant: "standard", margin: "none",  sx: { maxWidth: "125px" ,input: {
              fontSize: { xs: "10x", md: "13px" }, // Adjusts the text size inside the input
            },}, } }}
            defaultValue={dayjs(format(dateObject.start, "yyyy-mm-dd"))}
            disableFuture
          />

          <DatePicker
            id="to"
            label="Final"
            value={dayjs(format(dateObject.end, "yyyy-MM-dd"))}
            onChange={handleEndDateChange}
            format="DD/MM/YYYY" // Set the desired format
            slotProps={{ textField: { size: "small", variant: "standard", margin: "none", 
              sx: { maxWidth: "125px",
            input: {
              fontSize: { xs: "10x", md: "13px" }, // Adjusts the text size inside the input
            },},  } }}
            defaultValue={dayjs(format(dateObject.end, "yyyy-mm-dd"))}
            disableFuture
          />
        </Grid>
        <Grid size="auto" display="flex" alignItems="center">
          <Tooltip title={errorText} placement="right" arrow>
            <ErrorOutlineIcon color="error" sx={{ display: errorText === null ? "none" : "" }} />
          </Tooltip>
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default DateRange;
