import React, { useEffect, useMemo, useState } from "react";
import {
  Grid2 as Grid,
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { roles } from "../../components/Helper/Enums";

const EmployeeUserRolesChange = (props) => {
  const {
    showButton,
    open,
    setOpen,
    EmployeeRoles,
    selectedEmployeeRole,
    handleConfirmRoleChange,
    // handleRemoveRole
  } = props;
  const [selectedRole, setSelectedRole] = useState(""); // Selected role state

  console.log(props);

  // Initialize the selected role when the dialog opens or selectedEmployeeRole changes
  useEffect(() => {
    
    if (selectedEmployeeRole) {
      setSelectedRole(selectedEmployeeRole.role_name);
    }
  }, [selectedEmployeeRole]);

  // Handle dialog close without changing the role
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {showButton === true ? (
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={() => setOpen(true)}
        >
          Cambiar
        </Button>
      ) : (
        ""
      )}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          <Grid container alignItems={"center"}>
            <Grid size={10} align="left">
              <Typography variant="h5" align="left">
                <Box sx={{ fontWeight: "bold" }}>Cambiar Permiso</Box>
              </Typography>
            </Grid>
            <Grid size={2} align="right">
              <IconButton onClick={() => handleCloseDialog()}>
                <CloseIcon fontSize="medium" color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h5" gutterBottom>
            Selecciona un nuevo permiso para el usuario:
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Rol</InputLabel>
            <Select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              label="Rol"
            >
              {roles.map((role) => (
                <MenuItem key={role.value} value={role.label}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>handleConfirmRoleChange(selectedEmployeeRole,selectedRole)} variant="contained"
            color="success">
            Confirmar
          </Button>
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            color="primary"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EmployeeUserRolesChange;
