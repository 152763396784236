import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  differenceInMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getDay,
  getWeeksInMonth,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import {
  CustomerStatus,
  GenderItems,
  GenderOptions,
  loanStatus,
  ReportMode,
  WeekDayDueToPayment,  
} from "../Helper/Enums";
import {
  AiFillInfoCircle,
  AiOutlineCheckCircle,
  AiOutlineWarning,
} from "react-icons/ai";
import { GiNightSleep } from "react-icons/gi";
import { VscDebugRestart } from "react-icons/vsc";
import { GiSheep } from "react-icons/gi";
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import { Box, Tooltip, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
// import { GiSheep } from "react-icons/gi";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import WarningIcon from "@mui/icons-material/Warning";
import PropTypes from "prop-types";

export const getValueFromEnum = (Enum, value, obj) => {
  var newValue = {};
  const stringValue = "" + value;
  // console.log('input', Enum, stringValue, obj)
  switch (Enum) {
    case "loanStatus":
      newValue = getEnumObjectByValue(loanStatus, stringValue);
      break;
    case "WeekDayDueToPayment":
      newValue = getEnumObjectByValue(WeekDayDueToPayment, stringValue);
      break;
    case "ReportMode":
      newValue = getEnumObjectByValue(ReportMode, stringValue);
      break;
    case "CustomerStatus":
      newValue = getEnumObjectByValue(CustomerStatus, stringValue);
      break;
    case "GenderOptions":
      newValue = getEnumObjectByValue(GenderOptions, stringValue);
      break;
    case "GenderItems":
      newValue = getEnumObjectByValue(GenderItems, stringValue);
      break;
    default:
      break;
  }
  return newValue;
};

export const getEnumObjectByValue = (options, value) => {
  var label = "";
  options.find(function (option, index) {
    // console.log(option)
    if (option.value === value) {
      label = option.label;
      return true;
    }
  });
  return label;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export function calculateRangeDates(mode, dateReference) {
  const Range = {};
  const currentDate = new Date();
  var newDate = dateReference ? dateReference : currentDate;
  switch (mode) {
    case "WEEK":
      Range.startDate = startOfWeek(newDate, { weekStartsOn: 1 });
      Range.endDate = addDays(endOfWeek(newDate, { weekStartsOn: 1 }), 0); // change the addDays to add -1 days to remove the sundays
      Range.steps = differenceInDays(Range.endDate, Range.startDate) + 1; // adding +1 to get full days
      break;
    case "MONTH":
      Range.startDate = startOfWeek(startOfMonth(newDate), { weekStartsOn: 1 });
      Range.endDate = endOfMonth(newDate);
      Range.steps = getWeeksInMonth(Range.startDate, { weekStartsOn: 1 });
      break;
    case "YEAR":
      Range.startDate = startOfWeek(startOfYear(newDate), { weekStartsOn: 1 });
      Range.endDate = endOfYear(newDate);
      Range.steps = differenceInMonths(Range.endDate, Range.startDate);
      break;
    default:
      break;
  }

  Range.startDay = format(Range.startDate, "dd/MM/yyyy");
  Range.endDay = format(Range.endDate, "dd/MM/yyyy ");

  return Range;
}

export function calculateReportDates(mode, currentDate) {
  var Data = {},
    datePayload = {};
  Data = calculateRangeDates(mode, currentDate);
  Data.Dates = [];
  var stepsBetween = Data.steps;

  var dateToStart = Data.startDate;
  while (stepsBetween > 0) {
    switch (mode) {
      case "WEEK":
        Data.Dates.push({
          dateStart: dateToStart,
          dateDay: getDay(dateToStart),
          dateEnd: endOfDay(dateToStart),
        });
        dateToStart = addDays(dateToStart, 1);
        break;
      case "MONTH":
        Data.Dates.push({
          dateStart: dateToStart,
          dateDay: getDay(dateToStart),
          dateEnd: endOfWeek(dateToStart),
        });
        dateToStart = addWeeks(dateToStart, 1);
        break;
      case "YEAR":
        Data.Dates.push({
          dateStart: dateToStart,
          dateDay: getDay(dateToStart),
          dateEnd: endOfMonth(dateToStart),
        });
        dateToStart = addMonths(dateToStart, 1);
        break;
      default:
        break;
    }
    stepsBetween = stepsBetween - 1;
  }
  return Data;
}

export const getTimeDifferenceInHours = () => {
  const currentDate = new Date();
  const timeZoneDifference = currentDate.getTimezoneOffset() / 60;
  return timeZoneDifference;
};

export const filterFilialsWithAccess = (filials) => {
  for (var i = 0; i < filials.length; i++) {
    if (
      !checkAccess(filials[i].role_name, "Auditor") &&
      !checkAccess(filials[i].role_name, "Gerente Regional")
    ) {
      filials.splice(i, 1);
    }
  }
  return filials;
};

export const changeDateReference = (delta, mode, dateReference) => {
  // console.log('yeah', delta, reportMode, dateReference);
  const currentDate = new Date();
  var newDate = dateReference ? dateReference : currentDate;
  if (delta) {
    switch (mode) {
      case "WEEK":
        newDate = addWeeks(newDate, delta);
        break;
      case "MONTH":
        newDate = addMonths(newDate, delta);
        break;
      default:
        break;
    }
    if (newDate > currentDate) return currentDate;
    return newDate;
  }
  return newDate;
};

//this function check/validate if the "currentRole" has the right access (or higher) "AccessRole"
export const checkMinAccess = (filials, AccessRole) => {
  // console.log(filials, AccessRole);
  if (filials) {
    const hasMinRole = filials.find((filial) =>
      checkAccess(filial.role_name, AccessRole)
    );
    return hasMinRole ? true : false;
  }
  return false;
};

//this function check/validate if the "currentRole" has the right access (or higher) "AccessRole"
export const checkAccess = (currentRole, AccessRole) => {
  // console.log(currentRole, AccessRole);
  switch (AccessRole) {
    case "Gerente":
      return (
        currentRole === "Gerente" ||
        currentRole === "Auditor" ||
        currentRole === "Gerente Regional" ||
        currentRole === "Administrador" ||
        currentRole === "Root"
      );
    case "Auditor":
      return (
        currentRole === "Auditor" ||
        currentRole === "Administrador" ||
        currentRole === "Root"
      );
    case "Gerente Regional":
      return (
        currentRole === "Gerente Regional" ||
        currentRole === "Administrador" ||
        currentRole === "Root"
      );
    case "Auditor o Gerente Regional":
      return (
        currentRole === "Auditor" ||
        currentRole === "Gerente Regional" ||
        currentRole === "Administrador" ||
        currentRole === "Root"
      );
    case "Administrador":
      return currentRole === "Administrador" || currentRole === "Root";
    case "Root":
      return currentRole === "Root";
    default:
      return false;
  }
};

// this function check if the currentUser with "currentRole" has edit/delete right over the selected data from the employee with "AccessRole"
export const canEditDeleteEmployee = (currentRole, AccessRole) => {
  // console.log('Check:',currentRole,AccessRole);
  switch (AccessRole) {
    case "Empleado":
      return (
        currentRole === "Auditor" ||
        currentRole === "Gerente Regional" ||
        currentRole === "Administrador" ||
        currentRole === "Root"
      );
    case "Gerente":
      return (
        currentRole === "Auditor" ||
        currentRole === "Gerente Regional" ||
        currentRole === "Administrador" ||
        currentRole === "Root"
      );
    case "Auditor":
      return currentRole === "Administrador" || currentRole === "Root";
    case "Gerente Regional":
      return currentRole === "Administrador" || currentRole === "Root";
    case "Administrador":
      return currentRole === "Root";
    case "Root":
      return currentRole === "Root";
    default:
      return false;
  }
};

//this function return the list of roles that are accessible for the "currentRole" (those roles that are in a lower herarchy)
export const getUserRolesOptions = (data, currentRole) => {
  var index = data.findIndex((element) => element.role_type === "Root");
  data.splice(index, 1);

  switch (currentRole) {
    case "Administrador":
      index = data.findIndex(
        (element) => element.role_type === "Administrator"
      );
      data.splice(index, 1);
      break;
    case "Gerente Regional":
      index = data.findIndex(
        (element) => element.role_type === "Administrator"
      );
      data.splice(index, 1);
      index = data.findIndex(
        (element) => element.role_type === "RegionalManager"
      );
      data.splice(index, 1);
      index = data.findIndex((element) => element.role_type === "Auditor");
      data.splice(index, 1);
      break;
    case "Auditor":
      index = data.findIndex(
        (element) => element.role_type === "Administrator"
      );
      data.splice(index, 1);
      index = data.findIndex(
        (element) => element.role_type === "RegionalManager"
      );
      data.splice(index, 1);
      index = data.findIndex((element) => element.role_type === "Auditor");
      data.splice(index, 1);
      break;
    case "Gerente":
      index = data.findIndex(
        (element) => element.role_type === "Administrator"
      );
      data.splice(index, 1);
      index = data.findIndex(
        (element) => element.role_type === "RegionalManager"
      );
      data.splice(index, 1);
      index = data.findIndex((element) => element.role_type === "Auditor");
      data.splice(index, 1);
      index = data.findIndex((element) => element.role_type === "Gerente");
      data.splice(index, 1);
      break;
    default:
      break;
  }
  const userRoleOptions = data
    ? data.map((role) => {
      return { label: role.role_name, value: role.role_id };
    })
    : [];
  userRoleOptions.unshift({ label: "--Select--", value: "0" });
  return userRoleOptions;
};

//this function returns a default message for a No access area
export const noAccessMessage = () => {
  return "No tienes acceso a esta area!";
};

// Define a general sort comparator function numbers
export const generalSortComparatorNumbers = (valueGetterA, valueGetterB) => (
  valueA,
  valueB,
  paramsA,
  paramsB
) => {

  const realValueA = valueGetterA(paramsA);
  const realValueB = valueGetterB(paramsB);
  return realValueA - realValueB;
};

// Define a general sort comparator function for strings
export const generalSortComparatorStrings = (valueGetterA, valueGetterB) => (
  valueA,
  valueB,
  paramsA,
  paramsB
) => {
  const realValueA = valueGetterA(paramsA);
  const realValueB = valueGetterB(paramsB);

  // Compare the strings using localeCompare for sorting
  return realValueA.localeCompare(realValueB);
};

export const getCustomerStatusIcon = (rawStatus) => {
  let statusIcon = null;
  const statusLabel = getValueFromEnum("CustomerStatus", rawStatus);
  switch (rawStatus) {
    case "INACTIVE":
      statusIcon = <VscDebugRestart className="icon-small warning " />;
      // statusIcon = <AiOutlineCheckCircle className="icon-small good" />;
      break;
    case "ACTIVE":
      statusIcon = <GiSheep className="icon-small good" />;
      // statusIcon = <AiOutlineWarning className="icon-small warning" />;
      break;
    case "BLOCKED":
      statusIcon = <DoNotTouchIcon className="icon-small alarm" />;
      // statusIcon = <AiFillInfoCircle className="icon-small alarm" />;
      break;
    case "DISABLED":
      statusIcon = <GiNightSleep className="icon-small disabled" />
      // statusIcon = <DoNotTouchIcon className="icon-small disabled" />
      break;
  }
  return (
    <Tooltip title={statusLabel} placement="left" arrow>
      <Typography variant="body1" align="left" component="div">
        {statusIcon}
      </Typography>
    </Tooltip>
  );
};

export const getLoanStatusIcon = (rawStatus) => {
  let statusIcon = null;
  const statusLabel = getValueFromEnum("loanStatus", rawStatus);
  switch (rawStatus) {
    case "CLOSE":
      statusIcon = <CheckIcon color="primary" size={25} />;
      break;
    case "CANCEL":
      statusIcon = <HighlightOffRoundedIcon color="info" size={25} />;
      break;
    default:
      statusIcon = <GiSheep color="success" size={25} className="good" />;
      break;
  }
  return (
    <Tooltip title={statusLabel} placement="left" arrow>
      <Typography variant="body1" align="left" component="div">
        {statusIcon}
      </Typography>
    </Tooltip>
  );
};

export const renderIcon = (passedIcon) => {
  const Icon = passedIcon.icon;
  const itemIcon = passedIcon.icon ? <Icon sx={{ fontSize: 30, margin: 0, padding: 0 }} /> : false;
  return itemIcon;
};

export const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      sx={{
        width: "100%",
      }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}