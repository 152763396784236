import Person from '@mui/icons-material/Person';
import LinkIcon from '@mui/icons-material/Link';
import HandymanIcon from '@mui/icons-material/Handyman';
import TvIcon from '@mui/icons-material/Tv';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

export const Steps = [
    {label: "Datos personales",                 icon: Person},
    {label: "Referencias",                      icon: LinkIcon},
    {label: "Servicios con los que cuenta",     icon: HandymanIcon},
    {label: "Electrodomésticos",                icon: TvIcon},
    {label: "Foto de perfil",                   icon: AddPhotoAlternateIcon},
    {label: "Declaración",                      icon: DriveFileRenameOutlineIcon}
];

export const Utilities = [
    {label: "Agua",              value: "water"},
    {label: "Luz",               value: "electricity"},
    {label: "Telefóno",          value: "telephone"},
    {label: "Drenaje",           value: "drainage"},
];

export const ElectricDevices = [
    {label: "Televisor",         value: "television"},
    {label: "Bocina",            value: "horn"},
    {label: "Licuadora",         value: "blender"},
    {label: "Moto",              value: "motorbike"},
    {label: "Celular",           value: "phone"},
    {label: "PC/Tablet",         value: "pctablet"},
    {label: "Lavadora",          value: "washingmachine"},
    {label: "Ventilador",        value: "fan"}
];

export const GenderOptions = [
    { label: '--Select--',      value: '0' },
    { label: 'Masculino',       value: 'male' },
    { label: 'Femenino',        value: 'female' },
    { label: 'Otros',           value: 'other' },
];

export const GenderItems = [
    { title: 'Masculino',       id: 'male' },
    { title: 'Femenino',        id: 'female' },
    { title: 'Otros',           id: 'other' },
];

export const Months = [
    { label: '--Select--',      value: '0' },
    { label: '01 - Enero',      value: '1' },
    { label: '02 - Febrero',    value: '2' },
    { label: '03 - Marzo',      value: '3' },
    { label: '04 - Abril',      value: '4' },
    { label: '05 - Mayo',       value: '5' },
    { label: '06 - Junio',      value: '6' },
    { label: '07 - Julio',      value: '7' },
    { label: '08 - Agosto',     value: '8' },
    { label: '09 - Septiembre', value: '9' },
    { label: '10 - Octubre',    value: '10' },
    { label: '11 - Noviembre',  value: '11' },
    { label: '12 - Diciembre',  value: '12' }
];

export const WeekDayDueToPayment = [
    { label: 'Lunes',           value: '1' },
    { label: 'Martes',          value: '2' },
    { label: 'Miercoles',       value: '3' }
];

export const loanStatus = [
    // { label: 'Nuevo',           value: 'NEW' },
    { label: 'Abierto',         value: 'OPEN' },
    { label: 'Cerrado',         value: 'CLOSE' },
    { label: 'Cancelado',       value: 'CANCEL' }
];


export const ReportMode = [
    { label: 'Semanal',         value: 'WEEK' },
    { label: 'Mensual',         value: 'MONTH' },
    { label: 'Anual',           value: 'YEAR' }
];

// Status for the customer in the context of an active loan
export const CustomerStatus = [
    { label: 'ACTIVO',          value: 'ACTIVE'},
    // { label: 'INACTIVO',        value: 'INACTIVE'},
    { label: 'POR RENOVAR',        value: 'INACTIVE'},
    { label: 'BLOQUEADO',       value: 'BLOCKED'},
    // { label: 'INHABILITADO',    value: 'DISABLED'}, 
    { label: 'INACTIVO',    value: 'DISABLED'}, 
];

export const TransactionType = [
    { label: 'Entrada',         value: 'Investment' },
    { label: 'Salida',          value: 'MONTH' }
];

export const loanListMode = [
    { label: 'En mora',         value: 'OVERDUE' },
    { label: 'Al corriente',    value: 'ONTIME' },
    { label: 'Todos',           value: 'ALL' }
];

export const customerListMode = [
    { label: 'Todos',           value: '0' },
    { label: 'Activos',         value: '1' },
    { label: 'Por renovar',     value: '2' },
    { label: 'Bloqueados',      value: '3' }
];

export const cashFlowOptions = [
    { label: 'INGRESO DE CAPITAL',        value: '0' },
    { label: 'RETIRO DE CAPITAL',         value: '1' }
];

// Predefined roles
export const roles = [
    { label: "Empleado",            value: "Employee" },
    { label: "Gerente",             value: "Manager" },
    { label: "Auditor",             value: "Auditor" },
    { label: "Gerente Regional",    value: "RegionalManager" },
    { label: "Administrador",       value: "Administrator" },
    { label: "Root",                value: "Root" },
  ];