// Utility import
import React, { Component } from "react";
import { millisecondsToMinutes } from "date-fns";
import NovaStorage from "../Helper/NovaStorage";
import API from "../Helper/api";
import { NOVACOOKIE } from "../../utils/config/novacookies";
import { removeCookie, setCookie } from "../../lib/cookiesjs/cookiesjs";

// Data import (for local testing)
import { dummyComments } from "../../data/CommentData";

// Import config
import {
  AUTH,
  CUSTOMERS,
  EMPLOYEES,
  FILIALS,
  WORKSIN,
  LOANS,
  LOANINCREMENTS,
  REGISTRATIONS,
  USERROLES,
  CASHREGISTER,
  PAYMENTS,
  REPORTS,
  EXPENSES,
  PAYCHECKS,
  CASHFLOWS,
  CASHREGISTERREPORT,
  AUDITTRAIL,
  MEDIA,
  CLUSTER,
  CLUSTERACESS,
} from "../../utils/config/routes";

export const NovaContext = React.createContext();

export class Provider extends Component {
  state = {
    authenticatedUser:
      localStorage.getItem("nn-client") !== null
        ? JSON.parse(localStorage.getItem("nn-client"))
        : null,
    filial:
      localStorage.getItem("nn-client") !== null
        ? JSON.parse(localStorage.getItem("nn-client"))
        : "0",
    loading: true,
  };
  constructor() {
    super();
    this.NovaStorage = new NovaStorage();
    this.cookie = localStorage.getItem("nn-client");
    // this.state = this.validate()
    //   ? JSON.parse(this.cookie)
    //   : { authenticatedUser: null };
    // replaced 06.12 to handle cookie (nom nom) issue
    this.state = this.updateState(this.cookie);
    this.API = new API();
  }

  /************************************/
  /**** SECTION: Authentication ***** */
  /************************************/

  updateState = (stateObject) => {
    // console.log("in updateState: ", stateObject)
    if (this.validate()) {
      const localStorageNN = JSON.parse(stateObject);
      // console.log("setting new token: ", localStorageNN.accessToken)
      setCookie(NOVACOOKIE,localStorageNN.accessToken);
      return JSON.parse(this.cookie)
    } else {
      return { authenticatedUser: null };
    }

  }

  login = async (userCredentials) => {
    try {
      const body = {
        email: `${userCredentials.email}`,
        password: `${userCredentials.password}`,
      };
      // console.log('Login:',body
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${AUTH}/login`,
        "POST",
        body,
        false,
        null
      ).then((response) => {
        if (response.status === 201) {
          this.changeState({
            accessToken: response.data.accessToken,
            authenticatedUser: response.data.user,
            timestamp: new Date().valueOf(),
            themeMode: "light",
          });
          // remove NOVACOOKIE this once new token management is stable
          setCookie(NOVACOOKIE, response.data.accessToken);

          setCookie("nn-client-access", response.data.accessToken)
          setCookie("nn-client-refresh", response.data.refreshToken)
        }
        return response.status;
      });
    } catch (err) {
      console.log("An error occured: ", err);
    }
  };

  logout = () => {
    // Remove the session
    this.removeSession();

    // Remove the cookie using js-cookie utility function
    removeCookie("nn-client");
    removeCookie("nn-client-access");
    removeCookie("nn-client-refresh");

    // Reset state to logged-out values
    this.setState({
      authenticatedUser: null,
      accessToken: null,
      timestamp: 0,
      currentRole: null,
      filials: null,
      filial: null,
      selectedLoan: null,
    });
  };

  validate = () => {
    const authenticatedUser = this.state.authenticatedUser;
    const currentSession = this.NovaStorage.getNovaClient();
    if (currentSession && authenticatedUser) {
      const userSession = this.NovaStorage.getNovaClient();
      const sessionDelta = millisecondsToMinutes(
        new Date().valueOf() - userSession.timestamp
      );
      // remove session if older than 360 minutes
      if (sessionDelta > 360) {
        this.logout();
        console.log("User session has been terminated");
        return false;
      } else {
        return true;
      }
    }

    console.log("User session missing");
    return false;
  };

  changeThemeState = async (newTheme) => {
    this.changeState({
      themeMode: newTheme,
    });
    console.log("newTheme:", newTheme);
  };

  changeState = async (newState) => {
    this.setState(newState, () => {
      this.setSession(this.state);
      return true;
    });
  };

  setSelectedLoan = async (loanId) => {
    this.changeState({
      selectedLoan: loanId,
    });
  };

  /************************************/
  /****       SECTION: Session     ****/
  /************************************/

  getSession = () => {
    return this.NovaStorage.getNovaClient();
  };

  setSession = (state) => {
    this.NovaStorage.setNovaClient(state);
  };

  removeSession = () => {
    this.NovaStorage.removeNovaClient();
  };

  /************************************/
  /****  SECTION: Customer CRUD  ******/
  /************************************/

  addCustomer = async (customer) => {
    const jwt = this.state.accessToken;
    const filial = this.state.filial;
    const employee = this.state.authenticatedUser.employee_id;
    const body = {
      name: `${customer.name}`,
      lastname: `${customer.lastname}`,
      location: `${customer.location}`,
      password: `${customer.password}`,
      telephone: `${customer.telephone}`,
      filialId: `${filial}`,
      gender: `${customer.gender}`,
      employeeId: `${employee}`,
      hasLoanActive: false,
      address: `${customer.address}`,
      meterNumber: `${customer.meterNumber}`,
      comment: `${customer.comment}`,
      createdDate: `${customer.createdDate}`,
      dob: `${customer.dob}`,
    };
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}`;
    return await this.API.call(url, "POST", body, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return error;
        }
      });
  };

  updateCustomer = async (customer, id) => {
    const filial = this.state.filial;
    const employee = this.state.authenticatedUser.employee_id;
    console.log("parameter: ", customer);
    const body = {
      name: `${customer.name}`,
      lastname: `${customer.lastname}`,
      location: `${customer.location}`,
      password: `${customer.password}`,
      telephone: `${customer.telephone}`,
      filialId: `${filial}`,
      gender: `${customer.gender}`,
      employeeId: `${employee}`,
      address: `${customer.address}`,
      meterNumber: `${customer.meterNumber}`,
      comment: `${customer.comment}`,
      createdDate: `${customer.createdDate}`,
      dob: `${customer.dob}`,
    };

    console.log("index: ", body);

    return await this.API.call(
      `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/${id}`,
      "PUT",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, logging out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return null;
        }
      });
  };

  updateCustomerStatus = async (status, id) => {
    const filial = this.state.filial;
    const body = {
      status: `${status}`,
    };
    return await this.API.call(
      `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/Status/${id}?filial=${filial}`,
      "PUT",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => response)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return null;
        }
      });
  };

  updateCustomerPassword = async (password, id) => {
    const filial = this.state.filial;
    const body = {
      password: `${password}`,
    };
    // console.log("updating customer password", id, body);
    return await this.API.call(
      `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/password/${id}?filial=${filial}`,
      "PUT",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => response)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return null;
        }
      });
  };

  updateCustomerPasswordStatus = async (status, id) => {
    const filial = this.state.filial;
    const body = {
      passworddisabled: `${status}`,
    };
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/passworddisabled/${id}?filial=${filial}`;
    return await this.API.call(
      url,
      "POST",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return null;
        }
      });
  };

  updateCustomerComment = async (newComment, id) => {
    const filial = this.state.filial;
    const body = {
      comment: newComment,
    };
    return await this.API.call(
      `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/Comment/${id}?filial=${filial}`,
      "PUT",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return null;
        }
      });
  };

  deleteCustomer = async (customerId) => {
    const filial = this.state.filial;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/${customerId}?filial=${filial}`;
    return await this.API.call(
      url,
      "DELETE",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, logging out");
          console.log("Action aborted!");
          this.logout();
        } else {
          console.log("Error deleting customer", error);
          console.log("Please contact the system administrator!");
        }
        return false;
      });
  };

  getCustomer = async (customerId) => {
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/${customerId}`;
    return await this.API.call(
      url,
      "GET",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
        }
      });
  };

  getAllCustomers = async (Search) => {
    const filial = this.state.filial;
    const token = this.state.accessToken;
    const SearchParameters = Search
      ? Search.substring(1, Search.length) + "&filial=" + filial
      : "filial=" + filial;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}?${SearchParameters}`;
    return await this.API.call(url, "GET", null, true, token, null)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          console.log("Please contact the system administrator!");
        }
      });
  };

  validateCustomerPassword = async (password, id) => {
    const filial = this.state.filial;
    const body = {
      password: `${password}`,
    };
    return await this.API.call(
      `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/password/${id}?filial=${filial}`,
      "POST",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorized, logging out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return null;
        }
      });
  };

  /************************************/
  /****    SECTION: Loan CRUD    ******/
  /************************************/

  getLoansOpen = async (endDate, sorting) => {
    try {
      const filial = this.state.filial;
      const date = endDate ? endDate.toISOString() : "";
      const status = "OPEN";
      const sortingParameter = sorting
        ? sorting
        : "cus.customer_idnumber ASC, cus.customer_name ASC";
      const SearchParameters = endDate
        ? `filial=${filial}&status=${status}&sorting=${sortingParameter}&endDate=${date}`
        : `filial=${filial}&status=${status}&sorting=${sortingParameter}`;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/?${SearchParameters}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      if (err.response.status === 401) {
        console.log("Unauthorized, logging out");
        this.logout();
      } else {
        console.log("Error fetching worksIn data", err);
      }
      return null;
    }
  };

  getLoansClosed = async (endDate, sorting) => {
    try {
      const filial = this.state.filial;
      const date = endDate ? endDate.toISOString() : "";
      const status = "CLOSE";
      const sortingParameter = sorting
        ? sorting
        : "cus.customer_idnumber ASC, cus.customer_name ASC";
      const SearchParameters = endDate
        ? `filial=${filial}&status=${status}&sorting=${sortingParameter}&endDate=${date}`
        : `filial=${filial}&status=${status}&sorting=${sortingParameter}`;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/?${SearchParameters}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      // console.log(err.response.status);
      if (err.response.status === 401) {
        console.log("Unauthorized, logging out");
        this.logout();
      } else {
        console.log("Error fetching worksIn data", err);
      }
      return null;
    }
  };

  // ToDo: Replace with API method
  createLoan = async (loan) => {
    const body = {
      amount: `${loan.amount}`,
      dateIssue: loan.dateIssue,
      dateChange: loan.dateChange,
      dateDue: loan.dateDue,
      dueDay: loan.dueDay,
      status: `${loan.status}`,
      employeeId: `${loan.employeeId}`,
      customerId: `${loan.customerId}`,
      filialId: `${loan.filialId}`,
    };
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}`;
    const jwt = this.state.accessToken;

    return await this.API.call(url, "POST", body, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching loan data", error);
          return null;
        }
      });
  };

  addRiskToLoan = async (body) => {
    const { loanId, risk } = body;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/UpdateRisk/${loanId}`;

    return await this.API.call(
      url,
      "POST",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching loan data", error);
          return null;
        }
      });
  };

  calculateRemainingLoan = (loan) => {
    const AmountWithInteres = loan.loan_amount * 1.15;
    return AmountWithInteres;
  };

  updateLoan = async (loan, id) => {
    const body = {
      dueDate: loan.dueDate,
      dueDay: loan.dueDay,
      filialId: `${loan.filialId}`,
      // amount: `${loan.amount}`,
      // dateChange: loan.dateChange,
      // status: `${loan.status}`,
      // employeeId: `${loan.employeeId}`,
      // customerId: `${loan.customerId}`,
    };
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/${id}`;
    const token = this.state.accessToken;
    return await this.API.call(url, "PATCH", body, true, token, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error updating loan data", error);
          console.log("Please contact your system administrator");
          return null;
        }
      });
  };

  updateLoanOld = async (loan, id) => {
    const body = {
      amount: `${loan.amount}`,
      dateChange: loan.dateChange,
      dateDue: loan.dateDue,
      dueDay: loan.dueDay,
      status: `${loan.status}`,
      employeeId: `${loan.employeeId}`,
      customerId: `${loan.customerId}`,
      filialId: `${loan.filialId}`,
    };
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/${id}`;
    const token = this.state.accessToken;
    return await this.API.call(url, "PUT", body, true, token, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error creating or fetching loan data", error);
          console.log("Please contact your system administrator");
          return null;
        }
      });
  };

  getLoansFromCustomer = async (customerId) => {
    const filial = this.state.filial;
    const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/Customer/${customerId}?filial=${filial}`;
    return await this.API.call(
      endpoint,
      "GET",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorized, logging out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return null;
        }
      });
  };

  getActiveLoanFromCustomer = async (customerId) => {
    const filial = this.state.filial;
    return await this.API.call(
      `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/Customer/${customerId}?filial=${filial}`,
      "GET",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, logging out");
          this.logout();
        } else {
          console.log("Error fetching customer data", error);
          return null;
        }
      });
  };

  getLoanById = async (loanId) => {
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/${loanId}`;
    const token = this.state.accessToken;
    return await this.API.call(url, "GET", null, true, token, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching loan data", error);
        }
      });
  };

  setSelectedLoan = async (loanId) => {
    this.changeState({
      selectedLoan: loanId,
    });
  };

  deleteLoan = async (loanId) => {
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/${loanId}`;
    const token = this.state.accessToken;
    return this.API.call(url, "DELETE", null, true, token, null)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  getLoanFromMeterNumber = async (meterNumber) => {
    const filial = this.state.filial;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/MeterNumber/${meterNumber}?filial=${filial}`;
    const token = this.state.accessToken;
    return await this.API.call(url, "GET", null, true, token, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching loan from meter", error);
          console.log("Please contact the system administrator");
        }
      });
  };

  getLoansIssuedByEmployee = async (employeeId) => {
    const token = this.state.accessToken;
    const location = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/employeeloans?employee=${employeeId}`;
    return await this.API.call(location, "GET", null, true, token, null)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorised, logging out!");
        } else {
          console.log("error fetching loan data");
        }
      });
  };

  /************************************/
  /****   SECTION: Comment CRUD  ******/
  /************************************/

  // To delete
  getCustomerComments = async (customerId) => {
    return dummyComments.filter(
      (comment) => comment.customer_id === customerId
    );
  };

  // Investigate whether this can be removed
  createComment = async (comment) => {
    console.log(comment);
    await dummyComments.push(comment);
  };

  // To delete
  updateComment = async (comment) => {
    console.log(comment);
    var newComment = await dummyComments.find(
      (sourceComment) => comment.comment_id === sourceComment.comment_id
    );
    comment = newComment;
  };

  // To Delete
  deleteComment = async (comment) => {
    console.log(comment);
    const newComment = await dummyComments.find(
      (sourceComment) => comment.comment_id === sourceComment.comment_id
    );
    dummyComments.filter(
      (remainComment) => remainComment.comment_id !== newComment.comment_id
    );
  };

  /************************************/
  /***  SECTION: Multimedia CRUD  *****/
  /************************************/

  // getCustomerMedia = (customerId) => {
  //     return mediaData.filter(media => media.media_customerId === customerId);
  // };
  // temp method for styling purposes.
  getCustomerMedia = async (customerId) => {
    return await this.API.call(
      `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${MEDIA}/customer/${customerId}`,
      "GET",
      null,
      true,
      this.state.accessToken,
      null
    );
  };

  getMediaContents = async (key) => {
    return await this.API.call(
      `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${MEDIA}/mediaitem/${key}`,
      "GET",
      null,
      true,
      this.state.accessToken,
      null
    );
  };

  addMedia = async (image) => {
    const formData = new FormData();
    formData.append("image", image.image);
    formData.append("otherinfo", image.otherinfo);
    const headers = { "Content-Type": "multipart/form.data" };
    const link = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}`;
    // const link = "http://localhost:5000";
    return this.API.call(
      `${link}/${MEDIA}/images`,
      "POST",
      formData,
      true,
      this.state.accessToken,
      headers
    )
      .then((resp) => {
        return resp;
      })
      .catch((resp) => console.log("error", resp));
  };

  editMedia = async () => { };

  // need to delete this from the s3 bucket as well!
  deleteMedia = async (objectKey, customerId) => {
    const params = `objectKey=${objectKey}&filialId=${this.state.filial}&customerId=${customerId}`;
    const link = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${MEDIA}`;
    return await this.API.call(
      `${link}?${params}`,
      "DELETE",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((resp) => {
        return resp;
      })
      .catch((error) => console.log("error", error));
  };

  /************************************/
  /****  SECTION: Employee CRUD  ******/
  /************************************/

  addEmployee = async (employee) => {
    const jwt = this.state.accessToken;
    const filial = this.state.filial;
    const body = {
      name: `${employee.name}`,
      lastname: `${employee.lastname}`,
      email: `${employee.email}`,
      password: `${employee.password}`,
      telephone: `${employee.telephone}`,
      dateofbirth: `${employee.dateofbirth}`,
      sex: `${employee.sex}`,
    };
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EMPLOYEES}?filial=${filial}`;
    return await this.API.call(url, "POST", body, true, jwt, null)
      .then((response) => {
        const employeeId = response.data.employee_id;
        const WorksInBody = {
          roleId: `${employee.roleId}`,
          filialId: `${employee.filialId}`,
          employeeId: `${employeeId}`,
        };
        console.log(WorksInBody);
        const worksin = this.addWorksIn(WorksInBody);
        //redirect only if employee added -> return full employee object
        console.log(worksin);
        return employeeId;
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching employee data", error);
        }
      });
  };

  updateEmployee = async (employee, id) => {
    try {
      const filial = this.state.filial;
      const body = {
        name: `${employee.name}`,
        lastname: `${employee.lastname}`,
        email: `${employee.email}`,
        password: `${employee.password}`,
        telephone: `${employee.telephone}`,
        dateofbirth: `${employee.dateofbirth}`,
        sex: `${employee.sex}`,
      };
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EMPLOYEES}/${id}?filial=${filial}`,
        "PUT",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data.employee_id;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  deleteEmployee = async (employee) => {
    try {
      const filial = this.state.filial;
      const id = employee.employee_id;
      const WorksInId = employee.worksin_id;
      if (WorksInId) {
        const deletedWorksIn = this.deleteWorksIn(WorksInId);
      }
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EMPLOYEES}/${id}?filial=${filial}`,
        "DELETE",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => true);
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  updateEmployeePassword = async (body) => {
    try {
      const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EMPLOYEES}/password/${body.id}?filial=${filial}`,
        "PUT",
        body,
        true,
        this.state.accessToken,
        null
      )
        .then((response) => response.data)
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status === 401) {
            console.log("Unauthorized, login out");
            this.logout();
          } else {
            console.log("Error fetching customer data", error);
            return null;
          }
        });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getEmployee = async (employeeId) => {
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EMPLOYEES}/${employeeId}`;
    return await this.API.call(url, "GET", null, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching employee data", error);
        }
      });
  };

  getEmployeesFromFilial = async (filialId) => {
    const jwt = this.state.accessToken;
    const filial = filialId ? filialId : this.state.filial;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EMPLOYEES}?filial=${filial}`;
    return await this.API.call(url, "GET", null, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching employee data", error);
        }
      });
  };

  getAllEmployees = async () => {
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EMPLOYEES}?admin=true`;
    return await this.API.call(url, "GET", null, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching employee data", error);
        }
      });
  };

  /************************************/
  /****  SECTION: Filial CRUD  ******/
  /************************************/

  addFilial = async (filial) => {
    console.log("##fi:", filial);
    const body = {
      name: `${filial.name}`,
      address: `${filial.address}`,
      telephone: `${filial.telephone}`,
      cluster: `${filial.cluster}`,
    };
    const currentEmployee = this.state.authenticatedUser;
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${FILIALS}`;
    return await this.API.call(url, "POST", body, true, jwt, null)
      .then((response) => {
        const NewFilial = response.data;
        console.log(NewFilial, currentEmployee.employee_id);
        const RootRole = "7ee0c390-ad56-48dd-9f18-6e21cdeaca22";
        const WorksInBody = {
          roleId: `${RootRole}`,
          filialId: `${NewFilial.filial_id}`,
          employeeId: `${currentEmployee.employee_id}`,
        };
        console.log(WorksInBody);
        const worksin = this.addWorksIn(WorksInBody);
        console.log(worksin);
        return NewFilial;
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching filial data", error);
        }
        return null;
      });
  };

  updateFilial = async (filial) => { };

  deleteFilial = async (filialId) => {
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${FILIALS}/${filialId}`;
    return await this.API.call(url, "DELETE", null, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error deleting filial data", error);
        }
        return false;
      });
  };

  getFilial = async (filialId) => {
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${FILIALS}/${filialId}`;
    return await this.API.call(url, "GET", null, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching filial data", error);
        }
      });
  };

  setActiveFilial = async (filialId) => {
    const role = this.getActiveUserRole(filialId);
    const currentSession = this.NovaStorage.getNovaClient();
    currentSession.filial = filialId;
    currentSession.currentRole = role;
    this.setSession(currentSession);
    this.changeState((prevState) => {
      return {
        ...prevState,
        filial: filialId,
        currentRole: role,
      };
    });
    return true;
  };

  getActiveFilial = () => {
    const activeFilial = this.state.filial;
    return activeFilial;
  };

  getAllFilials = async (SearchParameters) => {
    const employee = this.state.authenticatedUser;
    const employeeId = employee
      ? employee.employee_id
      : "14f6dbde-4c46-40e5-a004-7781c5c46c7f";
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${FILIALS}?employee=${employeeId}&${SearchParameters}`;
    return await this.API.call(url, "GET", null, true, jwt, null)
      .then((response) => {
        // filter out keys address & telephone
        const newFil = response.data.map(
          ({ filial_address, telephone, worksin_roleid, ...rest }) => ({
            ...rest,
          })
        );

        this.changeState({
          filials: newFil,
        });
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching filial data", error);
        }
      });
  };

  updateFilialValues = async (body) => {
    const { filial, initialloanvalue, maxloanvalue, address, telephone } = body;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${FILIALS}/Values/${filial}`;
    return await this.API.call(
      url,
      "PUT",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        console.log(response);

        return response.data
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error deleting filial data", error);
        }
        return false;
      });
  };

  /************************************/
  /****    SECTION: WorksIn CRUD   ****/
  /************************************/

  addWorksIn = async (worksIn) => {
    try {
      const body = {
        filialId: `${worksIn.filialId}`,
        employeeId: `${worksIn.employeeId}`,
        roleId: `${worksIn.roleId}`,
      };
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${WORKSIN}`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      if (err.response.status === 401) {
        console.log("Unauthorized, logging out");
        this.logout();
      } else if (err.response.status === 401) {
        console.log("WorksIn already exists", err);
        return null;
      } else {
        console.log("Error fetching worksIn data", err);
      }
      return null;
    }
  };

  updateWorksIn = async (worksIn) => {
    try {
      const body = {
        filialId: `${worksIn.filialId}`,
        employeeId: `${worksIn.employeeId}`,
        roleId: `${worksIn.roleId}`,
        worksInId: `${worksIn.worksInId}`,
      };
      console.log("updating WorkisinBody", worksIn, body);
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${WORKSIN}`,
        "PUT",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      if (err.response.status === 401) {
        console.log("Unauthorized, logging out");
        this.logout();
      } else {
        console.log("Error fetching worksIn data", err);
      }
      return null;
    }
  };

  deleteWorksIn = async (body) => {
    try {
      const { worksInId, filialId, employeeId } = body;
      const filial = filialId ? filialId : this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${WORKSIN}/${worksInId}?employee=${employeeId}&filial=${filial}`,
        "DELETE",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => true);
    } catch (err) {
      // console.log(err.response.status);
      return this.validationStatusError(err);
    }
  };

  getWorksIn = async (worksInId) => {
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${WORKSIN}/${worksInId}`;
    return await this.API.call(url, "GET", null, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching worksIn data", error);
        }
      });
  };

  /********************************************/
  /****    SECTION: Registration CRUD    ******/
  /********************************************/

  addCustomerRegistration = async (registration) => {
    const body = {
      filialId: `${registration.filialId}`,
      employeeId: `${registration.employeeId}`,
      customerId: `${registration.customerId}`,
    };
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${REGISTRATIONS}`;
    return await this.API.call(url, "POST", body, true, jwt, null)
      .then((response) => response.data)
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else if (error.response.status === 401) {
          console.log("Registration already exists", error);
          return null;
        } else {
          console.log("Error fetching registration data", error);
        }
        return null;
      });
  };

  /************************************/
  /****     SECTION: UserRoles    *****/
  /************************************/

  getUserRoles = async () => {
    try {
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${USERROLES}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      console.log("An error occured: ", err);
    }
  };

  getActiveUserRole = (activeFilial) => {
    const filials = this.state.filials;
    const activeFilialObj = filials.filter((item) =>
      item.filial_id.includes(activeFilial)
    );
    const currentRole = activeFilialObj[0].role_name;
    return activeFilialObj ? currentRole : null;
  };

  /************************************/
  /****    SECTION: Reports    ******/
  /************************************/

  getReports = async (data) => {
    try {
      const body = data;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${REPORTS}`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getReportMatrix = async (data) => {
    try {
      const body = data;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${REPORTS}/Matrix`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        console.log(response.data);
        return response.data;
      });
    } catch (err) {
      // console.log(err.response.status);
      return this.validationStatusError(err);
    }
  };

  getLastCashRegisterReport = async (filialId) => {
    try {
      const filial = filialId ? filialId : this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CASHREGISTERREPORT}/getLast?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getCurrentCashRegisterReport = async (filialId) => {
    try {
      const filial = filialId ? filialId : this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CASHREGISTERREPORT}/getcreate?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  /************************************/
  /****    SECTION: CashRegister    ******/
  /************************************/

  getCashRegisterFromFilial = async (filialId) => {
    try {
      const filial = filialId ? filialId : this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CASHREGISTER}?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getCashRegisterWeeklyReport = async () => {
    try {
      const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CASHREGISTER}?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      // console.log(err.response.status);
      return this.validationStatusError(err);
    }
  };

  /************************************/
  /****    SECTION: Payments    ******/
  /************************************/

  getPayments = async () => {
    try {
      const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${PAYMENTS}?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getPaymentById = async (paymentId) => {
    try {
      const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${PAYMENTS}/${paymentId}?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getPaymentsSumFromRange = async (body) => {
    try {
      const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${PAYMENTS}/SumFromRange?filial=${filial}`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  deletePayment = async (paymentId) => {
    const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}`;
    return this.API.call(
      `${endpoint}/${PAYMENTS}/${paymentId}`,
      "DELETE",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  addPaymentToLoan = async (payment) => {
    try {
      const body = {
        total: `${payment.total}`,
        interest: `${payment.interest}`,
        arrear: `${payment.arrear}`,
        capital: `${payment.capital}`,
        createdate: `${payment.createdate}`,
        employeeId: `${payment.employeeId}`,
        loanId: `${payment.loanId}`,
        filialId: `${payment.filialId}`,
        openAmount: `${payment.openAmount}`,
        openArrear: `${payment.openArrear}`,
        dateDue: `${payment.dateDue}`,
      };
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${PAYMENTS}`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data[0];
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getPaymentsFromLoan = async (loanId) => {
    const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${PAYMENTS}/loan/${loanId}`;
    return await this.API.call(
      endpoint,
      "GET",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log("There was an error: ", err);
      });
  };

  /************************************/
  /****    SECTION: Expenses    ******/
  /************************************/

  getExpenses = async (filialId, startDate) => {
    try {
      const jwt = this.state.accessToken;
      const filial = filialId ? filialId : this.state.filial;
      var params = `?filial=${filial}`;
      if (startDate) {
        const date = startDate.toISOString();
        params = params + `&start=${date}`;
      }
      // console.log("params: ", params)
      const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EXPENSES}${params}`;
      // const localEndpoint = `${'http://localhost:5000'}/${EXPENSES}${params}`;
      return await this.API.call(url, "GET", null, true, jwt, null).then(
        (response) => {
          return response.data;
        }
      );
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  addExpense = async (expense) => {
    try {
      const body = {
        amount: `${expense.amount}`,
        description: `${expense.description}`,
        createdate: `${expense.createdate}`,
        employeeId: `${expense.employeeId}`,
        filialId: `${expense.filialId}`,
      };
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EXPENSES}`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  deleteExpense = async (Id) => {
    const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}`;
    return this.API.call(
      `${endpoint}/${EXPENSES}/${Id}`,
      "DELETE",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  /************************************/
  /****    SECTION: PayChecks    ******/
  /************************************/

  getPayChecks = async (filialId, startDate) => {
    try {
      const jwt = this.state.accessToken;
      const filial = filialId ? filialId : this.state.filial;
      var params = `?filial=${filial}`;
      if (startDate) {
        const date = startDate.toISOString();
        params = params + `&start=${date}`;
      }
      const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${PAYCHECKS}${params}`;
      return await this.API.call(url, "GET", null, true, jwt, null).then(
        (response) => {
          return response.data;
        }
      );
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  addPayCheck = async (paycheck) => {
    try {
      console.log("pagando");

      const body = {
        amount: `${paycheck.amount}`,
        description: `${paycheck.description}`,
        createdate: `${paycheck.createdate}`,
        employeeId: `${paycheck.employeeId}`,
        filialId: `${paycheck.filialId}`,
      };
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${PAYCHECKS}`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  deletePayCheck = async (Id) => {
    const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}`;
    return this.API.call(
      `${endpoint}/${PAYCHECKS}/${Id}`,
      "DELETE",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  /************************************/
  /****    SECTION: CashFlows    ******/
  /************************************/

  getCashFlows = async () => {
    try {
      const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CASHFLOWS}?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  addCashFlow = async (cashflow) => {
    try {
      const body = {
        amount: `${cashflow.amount}`,
        description: `${cashflow.description}`,
        createdate: `${cashflow.createdate}`,
        employeeId: `${cashflow.employeeId}`,
        filialId: `${cashflow.filialId}`,
      };
      const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}`;
      return await this.API.call(
        `${endpoint}/${CASHFLOWS}`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  deleteCashFlow = async (Id) => {
    const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}`;
    return this.API.call(
      `${endpoint}/${CASHFLOWS}/${Id}`,
      "DELETE",
      null,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };

  /*****************************************/
  /****    SECTION: LoanIncrements    ******/
  /*****************************************/

  getLoanIncrements = async () => {
    try {
      const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANINCREMENTS}?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getLoanIncrement = async (loanIncrement) => {
    try {
      const filial = this.state.filial;
      const value = loanIncrement.value;

      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANINCREMENTS}/Item?filial=${filial}&value=${value}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getLoanIncrementNextValue = async (body) => {
    try {
      const filial = this.state.filial;
      // const value = loanIncrement.value;
      const { value, renewals } = body;
      const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANINCREMENTS}/NextItemValue?filial=${filial}&value=${value}&renewals=${renewals}`;
      // for testing - please delete when finished
      // const url = `http://localhost:5000/${LOANINCREMENTS}/NextItemValue?filial=${filial}&value=${value}&renewals=${renewals}`;

      return await this.API.call(
        url,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  addLoanIncrement = async (loanIncrement, filialid) => {
    try {
      const filial = filialid ? filialid : this.state.filial;
      const body = {
        filialId: `${filial}`,
        value: `${loanIncrement.value}`,
        renewals: `${loanIncrement.renewals}`,
      };
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANINCREMENTS}`,
        "POST",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  updateLoanIncrement = async (loanIncrement) => {
    try {
      const filial = this.state.filial;
      const body = {
        filialId: `${filial}`,
        value: `${loanIncrement.value}`,
        renewals: `${loanIncrement.renewals}`,
      };

      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANINCREMENTS}`,
        "PUT",
        body,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  setLoanRaiseBlock = async (customerId, newLimitStatus) => {
    try {
      const raiseLimitBody = {
        customerId,
        filial: this.state.filial,
        newLimitStatus,
      };
      const token = this.state.accessToken;
      const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/limitstatus`;

      return await this.API.call(
        endpoint,
        "POST",
        raiseLimitBody,
        true,
        token,
        null
      ).then((resp) => {
        return resp;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  setCustomCreditLimit = async (
    newCreditLimit,
    oldCreditLimit,
    updatedCounterValue,
    customerId
  ) => {
    try {
      const token = this.state.accessToken;
      const customCreditLimit = {
        customerId,
        filial: this.state.filial,
        newCreditLimit,
        oldCreditLimit,
        updatedCounterValue,
      };
      console.log("context: ", customCreditLimit)
      const endpoint = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/customcreditlimit`;
      return await this.API.call(
        endpoint,
        "POST",
        customCreditLimit,
        true,
        token,
        null
      ).then((resp) => {
        return resp;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  /************************************/
  /****    SECTION: Counters    ******/
  /************************************/

  countEmployees = async () => {
    try {
      const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${EMPLOYEES}/count?filial=${filial}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => (response.data ? response.data : 0));
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  countCustomers = async (filialId, endDate) => {
    try {
      const filial = filialId ? filialId : this.state.filial;
      var params = `?filial=${filial}`;
      if (endDate) {
        const date = endDate.toISOString();
        params = params + `&endDate=${date}`;
      }
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/count${params}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => (response.data ? response.data : 0));
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  countActiveCustomers = async (filialId, endDate) => {
    try {
      const filial = filialId ? filialId : this.state.filial;
      var params = `?filial=${filial}`;
      if (endDate) {
        const date = endDate.toISOString();
        params = params + `&endDate=${date}`;
      }
      // console.log('Params', params)
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CUSTOMERS}/countActive${params}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => (response.data ? response.data : 0));
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  countLoans = async (status, endDate, filialId) => {
    try {
      const filial = filialId ? filialId : this.state.filial;

      var params = `?filial=${filial}`;
      if (endDate) {
        const date = endDate.toISOString();
        params = params + `&endDate=${date}`;
      }
      if (status) {
        params = params + `&status=${status}`;
      }
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/count${params}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data ? response.data : 0;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  sumLoansRemaining = async (endDate, filialId) => {
    try {
      const filial = filialId ? filialId : this.state.filial;
      var params = `?filial=${filial}&status=OPEN`;
      if (endDate) {
        const date = endDate.toISOString();
        params = params + `&endDate=${date}`;
      }
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/sumLoansRemaining${params}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data ? response.data : 0;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  countArrears = async (filialId) => {
    try {
      const filial = filialId ? filialId : this.state.filial;
      var params = `?filial=${filial}`;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${LOANS}/countArrears${params}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => response.data);
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  /*************************************/
  /****    SECTION: Validations    ******/
  /*************************************/

  validationStatusError = (error) => {
    if (error.response && error.response.status) {
      if (error.response.status === 401) {
        console.log("Unauthorized, logging out");
        this.logout();
        return false;
      } else if (error.response.status === 404) {
        console.log("xxx already exists", error);
        return null;
      } else {
        console.log("Error fetching data", error);
      }
    }
    return null;
  };

  validateEmployee = (employee) => {
    console.log(employee);
    let nameError = "";
    // let lastnameError = "";
    let emailError = "";
    let passwordError = "";
    // let password2Error = "";
    // let dateofbirthError = "";
    // let sex = "";

    if (!employee.name) {
      nameError = "Name es un campo obligatorio";
    }
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // if (!employee.email || reg.test(employee.email) === false) {
    //     emailError = "Email es un campo obligatorio";
    // }
    // if (!employee.password) {
    //     passwordError = "Contraseña es un campo obligatorio";
    // }
    if (emailError || nameError || passwordError) {
      this.changeState({
        formErrors: { nameError, emailError, passwordError },
      });
      return false;
    }
    return true;
  };

  /*************************************/
  /****    SECTION: ClusterData    ******/
  /*************************************/

  getClusterList = async () => {
    try {
      const employee = this.state.authenticatedUser.employee_id;
      // const filial = this.state.filial;
      return await this.API.call(
        `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CLUSTER}/?employee=${employee}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  addCluster = async (cluster) => {
    // console.log("##clu:", cluster);
    const body = { name: `${cluster.name}`, company: `${cluster.company}` };
    const currentEmployee = this.state.authenticatedUser;
    const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CLUSTER}`;
    return await this.API.call(
      url,
      "POST",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        const NewCluster = response.data;
        console.log(NewCluster, currentEmployee.employee_id);
        const RootRole = "7ee0c390-ad56-48dd-9f18-6e21cdeaca22";
        const body2 = {
          cluster: `${NewCluster.id}`,
          employee: `${currentEmployee.employee_id}`,
        };
        console.log(body2);
        const clusterAccessObj = this.addClusterAccess(body2);
        console.log(clusterAccessObj);
        return NewCluster;
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching cluster data", error);
        }
        return null;
      });
  };

  addClusterAccess = async (body) => {
    const { employee, cluster } = body;
    const currentEmployee = this.state.authenticatedUser;
    // const jwt = this.state.accessToken;
    const url = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}/${CLUSTERACESS}`;
    return await this.API.call(
      url,
      "POST",
      body,
      true,
      this.state.accessToken,
      null
    )
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Unauthorized, login out");
          this.logout();
        } else {
          console.log("Error fetching cluster data", error);
        }
        return null;
      });
  };

  /*************************************/
  /****    SECTION: AuditTrail    ******/
  /*************************************/

  getAuditTrail = async (type, customer) => {
    try {
      const location = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}`;
      const filial = this.state.filial;
      const employee = this.state.authenticatedUser.employee_id;
      const currentRole = this.state.currentRole;
      return await this.API.call(
        `${location}/${AUDITTRAIL}/${type}?filial=${filial}&employee=${employee}&customer=${customer}&currentRole=${currentRole}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  getEmployeeAudit = async (employeeId) => {
    try {
      const location = `${process.env.REACT_APP_SERVER_IPADDRESS}:${process.env.REACT_APP_SERVER_PORT}`;
      const audits = this.API.call(
        `${location}/${AUDITTRAIL}/employee?employee=${employeeId}`,
        "GET",
        null,
        true,
        this.state.accessToken,
        null
      ).then((response) => {
        return response.data;
      });
      return audits;
    } catch (err) {
      return this.validationStatusError(err);
    }
  };

  /************************************/
  /****    SECTION: render    ******/
  /************************************/

  render() {
    //const { authenticatedUser } = this.state ;
    return (
      <NovaContext.Provider
        value={{
          authenticatedUser: this.state.authenticatedUser,
          filials: this.state.filials,
          filial: this.state.filial,
          customers: this.state.customers,
          currentRole: this.state.currentRole,
          formEmployee: this.state.formEmployee,
          formErrors: this.state.formErrors,
          accessToken: this.state.accessToken, 
          actions: {
            login: this.login,
            logout: this.logout,
            validate: this.validate,
            changeState: this.changeState,
            validationStatusError: this.validationStatusError,
            getJWTFromSession: this.getJWTFromSession,
            getEmployeeFromSession: this.getEmployeeFromSession,
            setActiveFilial: this.setActiveFilial,
            getActiveFilial: this.getActiveFilial,
            getCustomers: this.getAllCustomers,
            getCustomer: this.getCustomer,
            addCustomer: this.addCustomer,
            updateCustomer: this.updateCustomer,
            updateCustomerStatus: this.updateCustomerStatus,
            updateCustomerPassword: this.updateCustomerPassword,
            updateCustomerPasswordStatus: this.updateCustomerPasswordStatus,
            updateCustomerComment: this.updateCustomerComment,
            deleteCustomer: this.deleteCustomer,
            validateCustomerPassword: this.validateCustomerPassword,
            getComments: this.getCustomerComments,
            addComment: this.createComment,
            updateComment: this.updateComment,
            deleteComment: this.deleteComment,
            getFilials: this.getAllFilials,
            getFilial: this.getFilial,
            addFilial: this.addFilial,
            updateFilial: this.updateFilial,
            updateFilialValues: this.updateFilialValues,
            deleteFilial: this.deleteFilial,
            getEmployees: this.getEmployeesFromFilial,
            getAllEmployees: this.getAllEmployees,
            getEmployee: this.getEmployee,
            addEmployee: this.addEmployee,
            updateEmployee: this.updateEmployee,
            deleteEmployee: this.deleteEmployee,
            updateEmployeePassword: this.updateEmployeePassword,
            getWorksIn: this.getWorksIn,
            addWorksIn: this.addWorksIn,
            deleteWorksIn: this.deleteWorksIn,
            updateWorksIn: this.updateWorksIn,
            createMedia: this.addMedia,
            getMediaReference: this.getCustomerMedia,
            getMediaSource: this.getMediaContents,
            deleteMedia: this.deleteMedia,
            getLoansOpen: this.getLoansOpen,
            getLoansClosed: this.getLoansClosed,
            getUserRoles: this.getUserRoles,
            setActiveRole: this.setActiveRole,
            getActiveUserRole: this.getActiveUserRole,
            addLoan: this.createLoan,
            updateLoan: this.updateLoan,
            addRiskToLoan: this.addRiskToLoan,
            getLoan: this.getLoanById,
            getLoansFromCustomer: this.getLoansFromCustomer,
            getLoansIssuedByEmployee: this.getLoansIssuedByEmployee,
            setSelectedLoan: this.setSelectedLoan,
            getActiveLoanFromCustomer: this.getActiveLoanFromCustomer,
            revokeLoan: this.deleteLoan,
            getLoanFromMeterNumber: this.getLoanFromMeterNumber,
            addCustomerRegistration: this.addCustomerRegistration,
            countEmployees: this.countEmployees,
            countCustomers: this.countCustomers,
            countActiveCustomers: this.countActiveCustomers,
            countLoans: this.countLoans,
            countArrears: this.countArrears,
            sumLoansRemaining: this.sumLoansRemaining,
            validateEmployee: this.validateEmployee,
            getPayments: this.getPayments,
            getPayment: this.getPaymentById,
            getPaymentsSum: this.getPaymentsSumFromRange,
            getPaymentsFromLoan: this.getPaymentsFromLoan,
            addPaymentToLoan: this.addPaymentToLoan,
            revokePayment: this.deletePayment,
            getExpenses: this.getExpenses,
            addExpense: this.addExpense,
            revokeExpense: this.deleteExpense,
            getPayChecks: this.getPayChecks,
            addPayCheck: this.addPayCheck,
            revokePayCheck: this.deletePayCheck,
            getCashFlows: this.getCashFlows,
            addCashFlow: this.addCashFlow,
            revokeCashFlow: this.deleteCashFlow,
            getCashRegister: this.getCashRegisterFromFilial,
            getCashRegisterWeeklyReport: this.getCashRegisterWeeklyReport,
            getReports: this.getReports,
            getReportMatrix: this.getReportMatrix,
            getLastCashRegisterReport: this.getLastCashRegisterReport,
            getCurrentCashRegisterReport: this.getCurrentCashRegisterReport,
            getAuditTrail: this.getAuditTrail,
            getEmployeeAudit: this.getEmployeeAudit,
            getLoanIncrements: this.getLoanIncrements,
            getLoanIncrement: this.getLoanIncrement,
            getLoanIncrementNextValue: this.getLoanIncrementNextValue,
            addLoanIncrement: this.addLoanIncrement,
            updateLoanIncrement: this.updateLoanIncrement,
            setLoanRaiseBlock: this.setLoanRaiseBlock,
            setCustomCreditLimit: this.setCustomCreditLimit,
            getClusterList: this.getClusterList,
            addCluster: this.addCluster,
            addClusterAccess: this.addClusterAccess,
            changeThemeState: this.changeThemeState,
            // getWorksIn: this.getWorksIn,
          },
        }}
      >
        {this.props.children}
      </NovaContext.Provider>
    );
  }
}

export const Consumer = NovaContext.Consumer;

/**
 * A higher order component that wraps the provided component in a context consumer component
 * This will ensure that the state is not loaded on the page before the existence of the user is checked
 * @param {class} Component: A higher order component
 * @returns {function} A higher-order component
 */

export default function withContext(Component) {
  return function ContextComponent(props) {
    return (
      <NovaContext.Consumer>
        {(context) => <Component {...props} context={context} />}
      </NovaContext.Consumer>
    );
  };
}
