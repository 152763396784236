import React, { useEffect, useState } from "react";


import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { alerts, displayAlert } from "../../components/Helper/AlertToastify";
import CustomButton from "../../components/common/CustomButton";


const LoanIncrementForm = ({
  open,
  setOpen,
  showButton,
  recordForEdit,
  addOrEditLoanIncrement
}) => {
  // Default values for the form
  const defaultValues = {
    id: 0,
    value: 0,
    renewals: 0,
  };
  
  const [loanDetails, setLoanDetails] = useState(defaultValues);
  const [errors, setErrors] = useState([]);
  const [locked, setLocked] = useState(false);

  // Close dialog and reset form
  const handleClose = () => {
    setLoanDetails(defaultValues);
    setOpen(false);
  };

  // Validate fields
  const validate = () => {
    let validateStatus = true;
    const tempErrors = [];

    if (loanDetails.value <= 0) {
      tempErrors.push({
        label: "value",
        message: "El valor debe ser superior a 0.",
      });
      validateStatus = false;
    }

    if (loanDetails.renewals < 0) {
      tempErrors.push({
        label: "renewals",
        message: "El número de renovaciones debe ser mayor o igual a 0.",
      });
      validateStatus = false;
    }

    setErrors(tempErrors);
    return validateStatus;
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    if (!locked) {
      setLocked(true);
      if (validate()) {
        try {
          await addOrEditLoanIncrement(loanDetails);
          displayAlert(alerts.SUCCESS, "Operación completada con éxito.");
          setLocked(false);
          handleClose();
        } catch (error) {
          displayAlert(
            alerts.ERROR,
            "Algo salió mal al procesar la operación."
          );
          console.error("Error trace: ", error);
          setLocked(false);
        }
      }
      setLocked(false);
    }
  };

  // const addOrEdit = async (loanDetails) => {
  //   if (loanDetails.id === 0) {
  //     await actions.addLoanIncrement(loanDetails);
  //     console.log("new");
  //   } else {
  //     console.log("cambio");
  //     await actions.updateLoanIncrement(loanDetails);
  //   }
  // };

  // Update form values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoanDetails({
      ...loanDetails,
      [name]: value,
    });
  };

  // Populate form when editing
  useEffect(() => {
    if (recordForEdit) {
      setLoanDetails(recordForEdit);
    }
  }, [recordForEdit]);

  // Check for validation errors
  const checkForErrors = (fieldId) => {
    const fieldError = errors.find((error) => error.label === fieldId);
    return fieldError ? fieldError.message : "";
  };

  return (
    <React.Fragment>
      {showButton === true ? (
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={() => setOpen(true)}
        >
          + Incrementos
        </Button>
      ) : (
        ""
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container alignItems={"center"}>
            <Grid size={10}>
              <Typography variant="h5">
                <Box sx={{ fontWeight: "bold" }}>Actualizar valor</Box>
              </Typography>
            </Grid>
            <Grid size={2} align="right">
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="medium" color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            name="value"
            label="Valor del incremento"
            type="number"
            variant="outlined"
            fullWidth
            required
            autoFocus
            onChange={handleInputChange}
            value={loanDetails.value}
            error={checkForErrors("value") ? true : false}
            helperText={checkForErrors("value")}
            disabled={locked}
          />

          <TextField
            name="renewals"
            label="Renovaciones"
            type="number"
            variant="outlined"
            fullWidth
            required
            onChange={handleInputChange}
            value={loanDetails.renewals}
            error={checkForErrors("renewals") ? true : false}
            helperText={checkForErrors("renewals")}
            disabled={locked}
            margin="normal"
          />
        </DialogContent>

        <DialogActions>
          <CustomButton
            buttonTrigger={handleSubmit}
            locked={locked}
            variant="contained"
            color="success"
            buttonText="Guardar"
          />

          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            disabled={locked}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default LoanIncrementForm;
