import { Box, Grid2 as Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NovaContext } from "../../components/context";
import { useLocation } from "react-router-dom";
import CustomerList from "./CustomerList";
import Loading from "../../components/Actions/Loading";
// import NewEditCustomer from "../../components/popups/NewEditCustomer";
import { ItemPaper } from "../../components/Helper/MUIStyledComponents";

const Index = () => {
  const { actions, filial } = useContext(NovaContext);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const Search = useLocation().search;
  let currentPath = useLocation();

  useEffect(() => {
    actions.getCustomers(Search).then((data) => {
      // console.log("Customers:", data);
      setCustomers(data);
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <Grid container sx={{ padding: { xs: " 10px", md: "25px" } }}>
      <div
        className={`over-page-loading ${
          loading ? "show-loading" : "hide-loading"
        }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando ..."}
            classNames={`over-page-loading ${
              loading ? "show-loading" : "hide-loading"
            }`}
          />
        </div>
      </div>

      {loading ? (
        <div>Cargando...</div>
      ) : (
        <Grid size={12}>
          <ItemPaper>
            <CustomerList customers={customers} setLockStatus={setLoading} />
          </ItemPaper>
        </Grid>
      )}
    </Grid>
  );
};

export default Index;
