import { Box, IconButton, Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TooltipIconButton from "../../../controls/TooltipIconButton";
import { MdPlaylistRemove, MdRebaseEdit } from "react-icons/md";
import { checkAccess } from "../../GlobalHelpers";

export const prepareEmployeeListAllColumns = (
  employees,
  handleViewEmployee
) => {
  const columns = [
    {
      field: "accion",
      headerName: "",
      sortable: false,
      width: 60,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems={"center"}>
            <Tooltip
              id="button-employee-details"
              title="Ver detalles del empleado."
            >
              <IconButton
                // autoFocus
                color="primary"
                variant="contained"
                size="large"
                data-target={`#id${params?.row.employee_id}`}
                onClick={() => handleViewEmployee(params?.row.employee_id)}
              >
                {""}
                <OpenInNewIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "employee_name",
      headerName: "Nombre",
      headerClassName: "GridHeader",
      align: "left",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "employee_lastname",
      headerName: "Apellido",
      headerClassName: "GridHeader",
      align: "left",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "employee_email",
      headerName: "Correo",
      headerClassName: "GridHeader",
      align: "left",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "employee_telephone",
      headerName: "Telefono",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 110,
      maxWidth: 130,
    },
    {
      field: "rolesAndFilials",
      headerName: "Roles and Filials",
      headerClassName: "GridHeader",
      align: "left",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const values = params.value
          ? params.value.split(", ").map((item) => {
              const [filial_name, role_name] = item.split(" - ");
              return { filial_name, role_name };
            })
          : [];

        return (
          <ul>
            {values.map((item, index) => (
              <li key={index}>
                {item.filial_name} - {item.role_name}
              </li>
            ))}
          </ul>
        );
      },
      valueGetter: (value, row) => {
        const valueString = value
          .map((item) => `${item.filial_name} - ${item.role_name}`)
          .join(", ");
        return valueString;
      },
    },
  ];
  return [...columns];
};

export const prepareEmployeeUserRolesListColumns = (
  EmployeeRoles,
  handleRoleChangeClick,
  handleRemoveRoleClick
) => {
  const columns = [
    {
      field: "filial_name",
      headerName: "Nombre",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "role_name",
      headerName: "Permiso",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      renderCell: (params) => {
        const triggerRoleSelectionPage = (e) => {
          e.stopPropagation();
          handleRoleChangeClick(params?.row);
        };
        const triggerRemoveRoleConfirmation = (e) => {
          e.stopPropagation();
          handleRemoveRoleClick(params?.row);
        };

        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TooltipIconButton
              title="Editar permiso"
              onClick={triggerRoleSelectionPage}
              disabled={false}
              color="info"
              size="medium"
              variant="contained"
              icon={<MdRebaseEdit fontSize="large" />}
            />
            <TooltipIconButton
              title="Eliminar permiso"
              onClick={triggerRemoveRoleConfirmation}
              disabled={false}
              color="error"
              size="medium"
              variant="contained"
              icon={<MdPlaylistRemove fontSize="large" />}
            />
          </Box>
        );
      },
    },
  ];
  return [...columns];
};


export const visibilityEmployeeList = (currentRole) => {
  // const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  // const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  return {
    // filial_name: false,
    // role_name: false,
    actions: hasAdminAccess,
  };
};