import React, { useContext, useMemo } from "react";
import { NovaContext } from "../../components/context/index";

// Internal import
import { calculateOpenArrear } from "../../components/Helper/LoanHelpers";

// Material UI import
import Box from "@mui/system/Box";

import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";

//Icons-Material
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Date-fns imports
import { useNavigate } from "react-router-dom";
import { Grid2 as Grid, IconButton, Tooltip } from "@mui/material";
import {
  getCustomerStatusIcon,
  getValueFromEnum,
} from "../../components/Helper/GlobalHelpers";
import NewEditCustomer from "../../components/popups/NewEditCustomer";
import CustomLoadingOverlay from "../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";
import { renderNoRowDataOverlay } from "../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";

const CustomerList = React.memo((props) => {
  const { customers, setLockStatus } = props;
  const { currentRole } = useContext(NovaContext);
  //   const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  //   const hasAdminAccess = checkAccess(currentRole, "Administrador");
  let navigate = useNavigate();

  // const processedCustomers = useMemo(() => {
  //   if (customers) {
  //     console.log('Customers:', customers);
      
  //     return customers.map((item) => ({
  //       ...item,
  //       fullName: `${item.customer_name} ${item.customer_lastname}`,
  //     }));
  //   }
  //   return [];
  // }, [customers]);

  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const boxHeight = 800;

  const handleView = (customerId) => {
    const path = `${customerId}`;
    navigate(path);
  };

  const prepareColumns = (dataArray) => {
    const columns = [
      {
        field: "accion",
        headerName: "",
        sortable: false,
        width: 60,
        hideable: false,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          return (
            <Box display="flex" alignItems={"center"}>
              <Tooltip
                id="button-customer-details"
                title="Ver detalles del cliente."
              >
                <IconButton
                  // autoFocus
                  color="primary"
                  variant="contained"
                  size="large"
                  data-target={`#id${params?.row.customer_id}`}
                  onClick={() => handleView(params?.row.customer_id)}
                >
                  {""}
                  <OpenInNewIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        field: "customer_idnumber",
        headerName: "ID",
        align: "center",
        headerAlign: "center",
        type: "number",
        width: "50",
        hideable: false,
        headerClassName: "GridHeader",
      },
      {
        field: "customer_fullname",
        headerName: "Cliente",
        headerClassName: "GridHeader",
        align: "left",
        flex: 1,
        minWidth: 150,
        maxWidth: 300,
      },
      {
        field: "customer_telephone",
        headerName: "Telefono",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 100,
        maxWidth: 120,
      },
      {
        field: "customer_meternumber",
        headerName: "#Medidor",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 100,
        maxWidth: 120,
      },
      {
        field: "customer_location",
        headerName: "Localidad",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 150,
        maxWidth: 350,
      },
      {
        field: "customer_address",
        headerName: "Direccion",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 200,
        maxWidth: 350,
      },
      {
        field: "loan_amount",
        headerName: "Credito Actual",
        headerClassName: "GridHeader",
        headerAlign: "right",
        align: "right",
        type: "number",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        renderCell: (params) => {
          // console.log('renderCell params:', params?.row.loan_amount,'value +', params);
          return params?.row.loan_amount ? `$${params?.row.loan_amount}` : "-";
        },
        valueGetter: (params) => (params ? `${params}` : "-"),
      },
      {
        field: "arrears",
        headerName: "Mora",
        headerClassName: "GridHeader",
        headerAlign: "right",
        align: "right",
        type: "number",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        renderCell: (params) => {
          // console.log('Params in renderCell:', params);
          return params?.row ? `$${calculateOpenArrear(params?.row)}` : "-";
        },
        valueGetter: (value, row) => `${calculateOpenArrear(row)}`,
      },
      // {
      //   field: "state",
      //   headerName: "Estado",
      //   headerClassName: "GridHeader",
      //   headerAlign: "center",
      //   align: "center",
      //   flex: 1,
      //   minWidth: 80,
      //   maxWidth: 120,
      //   // type: "singleSelect",
      //   // valueOptions: CustomerStatus,
      //   // valueOptions: ["ACTIVO", "INACTIVO", "BLOQUEADO", "INHABILITADO"],
      //   valueGetter: (params) => {
      //     const stat = getValueFromEnum("CustomerStatus", params);
      //     console.log(params,stat)
      //     // return getValueFromEnum("CustomerStatus", value);
      //     return stat; 
      //   },   
      //   renderCell: (params) => {
      //     console.log('P',params)
      //     return getCustomerStatusIcon(params?.row.status);
      //   },
      //   filterable: true,
      // },
      {
        field: "state",
        headerName: "Estado",
        headerClassName: "GridHeader",
        headerAlign: "center",
        align: "center",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        type: "singleSelect", 
        valueOptions: ["ACTIVO", "INACTIVO", "BLOQUEADO", "INHABILITADO"], 
        valueGetter: (value,row) => {
          return getValueFromEnum("CustomerStatus", row?.status);
        },
        renderCell: (params) => {
          return getCustomerStatusIcon(params?.row.status);
        },
        filterable: true,
        // sortComparator: (v1, v2) => v1.localeCompare(v2), // Adding a custom sort comparator
      },
      
      {
        field: "amountofloans",
        headerName: "Renovaciones",
        headerClassName: "GridHeader",
        headerAlign: "center",
        align: "center",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
      },
    ];
    return [...columns];
  };

  const prepareRows = (dataArray) => {
    return dataArray && dataArray[0]
      ? dataArray?.map((item) => {
          return {
            ...item,
          };
        })
      : [];
  };

  return (
    <Box height="85vh" width="100%">
      <CustomDataGrid
        columns={prepareColumns(customers)}
        rows={prepareRows(customers)}
        getRowId={(row) => row.customer_id}
        slots={{
          toolbar: CustomGridToolbar,
          loadingOverlay: CustomLoadingOverlay,
          noRowsOverlay: () =>
            renderNoRowDataOverlay("No hay créditos en este periodo"),
        }}
        slotProps={{
          toolbar: {
            component2: (
              <NewEditCustomer
                modalClass="modal"
                setLockStatus={setLockStatus}
              />
            ),
          },
        }}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        filterModel={filterModel}
        initialState={{
          filter: {
            filterModel: filterModel,
          },
        }}
      />
    </Box>
  );
});

export default CustomerList;
