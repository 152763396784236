import React, { useState, useContext } from "react";
import { NovaContext } from "../../components/context/index";

// React router dom
import { Link } from "react-router-dom";

// Internal import
import { WeekDayDueToPayment } from "../../components/Helper/Enums";
import {
  calculateDelayedWeeks,
  calculateOpenAmountTotal,
  calculateOpenArrear,
  calculateOpenInterest,
  calculateRisk,
  formatRiskIcon,
} from "../../components/Helper/LoanHelpers";
import {
  a11yProps,
  checkAccess,
  TabPanel,
} from "../../components/Helper/GlobalHelpers";

// Material UI import
import Box from "@mui/system/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  CustomDataGrid,
  CustomGridToolbar,
  ItemPaper,
  toolbarTranslations,
} from "../../components/Helper/MUIStyledComponents";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

// Risk factor display
import Critical from "@mui/icons-material/GppBad";
import Alarm from "@mui/icons-material/SafetyCheck";
import Warning from "@mui/icons-material/GppMaybe";
import Normal from "@mui/icons-material/VerifiedUser";

// Date-fns imports
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import LoanList from "../LoanOverview/LoanList";
import MediaGallery from "../../components/MediaGallery";
import ListEmployeeLoansBySearch from "../../components/Actions/ListEmployeeLoansBySearch";
import EmployeeUserRolesList from "./EmployeeUserRolesList";
import EmployeeDocuments from "./EmployeeDocuments";
import LoansList from "../LoansHistory/components/LoansList";
import { visibilityModelLoanEmployeeProfile } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Loans";
import LoansListWrapper from "../../components/Wrappers/LoansListWrapper";
import { visibilityEmployeeList } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Employee";

const EmployeeContent = (props) => {
  const {
    employee,
    employeeLoans,
    loansLoaded,
    viewLoanDetails,
    togglePaymentDialog,
    EmployeeRoles,
    updateEmployeeRole,
    removeEmployeeRole,
    customerAudit,
    auditLoaded,
    employeeMedia,
    mediaLoaded,
    updateMedia,
    userRoleList,
  } = props;

  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const boxHeight = "50vh";

  const { currentRole } = useContext(NovaContext);
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box margin={1} paddingTop={{ xs: 5, md: 0 }}>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
        >
          <Tab label="Permisos" {...a11yProps(0)} />
          <Tab label="Créditos" {...a11yProps(1)} />
          <Tab label="Auditoría" {...a11yProps(2)} />
          <Tab label="Imágenes" {...a11yProps(3)} />
          {/* <Tab label="Documentos" {...a11yProps(4)} /> */}
          <Tab label="Documentos de <name here>" {...a11yProps(5)} />
        </Tabs>
      </Box>

      <TabPanel value={selectedTab} label="Permisos" index={0}>
        <Box width="100%">
          <EmployeeUserRolesList
            EmployeeRoles={EmployeeRoles}
            columnVisibilityModel={visibilityEmployeeList}
            userRoleList={userRoleList}
            updateEmployeeRole={updateEmployeeRole}
            removeEmployeeRole={removeEmployeeRole}
          />
        </Box>
      </TabPanel>

      <TabPanel value={selectedTab} label="Créditos" index={1}>
        <Grid container size={{ xs: 12 }}>
          <Box height={boxHeight} width="100%">
            <LoansListWrapper
              columnVisibilityModel={visibilityModelLoanEmployeeProfile}
              statusFilter={"none"}
              sortingFilter={"lo.loan_dateissue DESC"}
              boxHeight={boxHeight}
              employeeId={employee.employee_id}
              startDateFilter={true}
              endDateFilter={true}
              hideDateRange={true}
            />
          </Box>
        </Grid>
      </TabPanel>

      {/* under construction */}
      <TabPanel value={selectedTab} label="Auditoría" index={2}>
        <Box height={boxHeight} width="100%"></Box>
      </TabPanel>

      {/* under construction */}
      <TabPanel value={selectedTab} label="Imágenes" index={3}>
        <Box height={boxHeight} width="100%">
          <Typography>No existen imagenes para este empleado.</Typography>
        </Box>
      </TabPanel>

      {/* <TabPanel value={selectedTab} label={`Documentos para ${employee.employee_fullname}`} index={5}> */}
      <TabPanel value={selectedTab} label={`Documentos`} index={4}>
        <Box height={boxHeight} width="100%">
          <EmployeeDocuments
            documentList={employeeMedia?.filter(
              (media) =>
                media.media_type === "DOCUMENT" &&
                media.media_foremployeeid !== null
            )}
            currentRole={currentRole}
            updateMedia={updateMedia}
          />
        </Box>
      </TabPanel>
    </Box>
  );
};

export default EmployeeContent;
