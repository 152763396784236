import React, { useContext, useState } from "react";
import { Grid2 as Grid, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";
import EmployeeUserRolesChange from "./EmployeeUserRolesChange";
import { prepareEmployeeUserRolesListColumns } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Employee";
import CustomLoadingOverlay from "../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";
import { renderNoRowDataOverlay } from "../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";
import { NovaContext } from "../../components/context";
import { alerts, displayAlert } from "../../components/Helper/AlertToastify";
import Confirmation from "../../components/common/Confirmation";

const boxHeight = 650;

const EmployeeUserRolesList = (props) => {
  const {
    EmployeeRoles,
    columnVisibilityModel,
    userRoleList,
    updateEmployeeRole,
    removeEmployeeRole,
  } = props;
  const { actions, currentRole } = useContext(NovaContext);

  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const [confirmationRemoveRole, setConfirmationRemoveRole] = useState(false);

  const [openDialog, setOpenDialog] = useState(false); // Dialog open/close state
  const [selectedEmployeeRole, setSelectedEmployeeRole] = useState(null); // The row that is being edited

  // Handle dialog opening and row selection for role change
  const handleRoleChangeClick = (params) => {
    console.log(params);
    setSelectedEmployeeRole(params); // Set the row being edited
    setOpenDialog(true); // Open the dialog
  };

  const handleRemoveRoleClick = (params) => {
    console.log(params);
    setSelectedEmployeeRole(params); // Set the row being edited
    setConfirmationRemoveRole(true);
  };

  // Handle role change confirmation
  const handleConfirmRoleChange = async (
    selectedEmployeeRole,
    selectedRole
  ) => {
    console.log("Cambiando permiso:", selectedEmployeeRole, selectedRole);

    const selecterRoleObj = userRoleList.find(
      (role) => role.role_name === selectedRole
    );

    const body = {
      worksInId: `${selectedEmployeeRole.worksin_id}`,
      filialId: `${selectedEmployeeRole.worksin_filialid}`,
      employeeId: `${selectedEmployeeRole.employee_id}`,
      roleId: `${selecterRoleObj.role_id}`,
    };
    console.log(body);

    const roleChanged = await actions
      .updateWorksIn(body)
      .then((resp) => {
        displayAlert(alerts.SUCCESS, "Permiso cambiado con éxito");
        updateEmployeeRole(selectedEmployeeRole.worksin_id, selectedRole);
        setOpenDialog(false);
      })
      .catch((resp) => {
        // setLocked(false);
        displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
      });

    setOpenDialog(false); // Close the dialog
  };

  // Handle role removal
  const handleRemoveRole = async (selectedEmployeeRole) => {
    console.log("Eliminando permiso:", selectedEmployeeRole);

    // const body = {
    //   worksInId: `${selectedEmployeeRole.worksin_id}`,
    //   filialId: `${selectedEmployeeRole.worksin_filialid}`,
    //   employeeId: `${selectedEmployeeRole.employee_id}`,
    //   // roleId: `${selecterRoleObj.role_id}`,
    // };
    // console.log(body);

    // await actions
    //   .deleteWorksIn(body)
    //   .then((resp) => {
    //     displayAlert(alerts.SUCCESS, "Permiso removido con éxito");
    //     removeEmployeeRole(selectedEmployeeRole.worksin_id);
    //     setOpenDialog(false);
    //   })
    //   .catch((resp) => {
    //     // setLocked(false);
    //     displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
    //   });
    setConfirmationRemoveRole(false); // Close the dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const visibilityModel = columnVisibilityModel(currentRole);

  return (
    <Box sx={{ width: "100%", height: "100%", overflowX: "auto" }}>
      <Box height={boxHeight} width="100%">
        <CustomDataGrid
          getRowId={(row) => row.worksin_id}
          rows={EmployeeRoles}
          columns={prepareEmployeeUserRolesListColumns(
            EmployeeRoles,
            handleRoleChangeClick,
            handleRemoveRoleClick
          )}
          slots={{
            toolbar: CustomGridToolbar,
            loadingOverlay: CustomLoadingOverlay,
            noRowsOverlay: () =>
              renderNoRowDataOverlay("No hay permisos para este usuario"),
          }}
          slotProps={{
            toolbar: {
              component1: null,
              component2: null,
            },
          }}
          // onFilterModelChange={(newModel) => setFilterModel(newModel)}
          // filterModel={filterModel}
          initialState={{
            columns: {
              columnVisibilityModel: visibilityModel,
            },
            filter: {
              filterModel: filterModel,
            },
          }}
        />
      </Box>

      <EmployeeUserRolesChange
        showButton={false}
        open={openDialog}
        setOpen={setOpenDialog}
        EmployeeRoles={EmployeeRoles}
        handleConfirmRoleChange={handleConfirmRoleChange}
        selectedEmployeeRole={selectedEmployeeRole}
        // handleRemoveRole={handleRemoveRole}
      />

      <Confirmation
        open={confirmationRemoveRole}
        setOpen={setConfirmationRemoveRole}
        textContent={"Estás seguro de que quieres REMOVER este permiso?"}
        confirmationMethod={handleRemoveRole}
      />
    </Box>
  );
};

export default EmployeeUserRolesList;
