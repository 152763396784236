import React, { useContext, useState } from "react";

// Internal import
import { NovaContext } from "../context";
import { getTimeDifferenceInHours } from "../Helper/GlobalHelpers";
import { alerts, displayAlert } from "../Helper/AlertToastify";
import { addPaycheck } from "../../utils/api/api_paychecks";

// Misc import
import { addHours, formatISO } from "date-fns";

// MUI import
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../common/CustomButton";

const NewPayCheck = (props) => {
  const { open, setOpen, updatePaychecks, showButton } = props;

  // form controls
  const [locked, setLocked] = useState(false);
  const [errors, setErrors] = useState([]);
  const { actions, filial, authenticatedUser } = useContext(NovaContext);

  const handleClose = () => {
    setPayCheck(defaultValues);
    setOpen(false);
  };

  const checkForErrors = (fieldId) => {
    const nameError = errors.find((error) => error.label === fieldId);
    return nameError !== undefined ? nameError.message : "";
  };

  // form contents
  const defaultValues = {
    amount: 0,
    description: "",
  };

  const [payCheck, setPayCheck] = useState(defaultValues);

  // form methods
  const handleInputchange = (e) => {
    const { name, value } = e.target;
    // console.log("name: ", name);
    // console.log("value: ", value);
    setPayCheck({
      ...payCheck,
      [name]: value,
    });
  };

  const validate = () => {
    let validateStatus = true;
    if (payCheck.amount < 1) {
      setErrors((arr) => [
        ...arr,
        { label: "amount", message: "El valor debe ser superior o igual a 1" },
      ]);
      validateStatus = false;
    }
    if (
      payCheck.description === "undefined" ||
      payCheck.description === "null" ||
      payCheck.description.length === 0
    ) {
      setErrors((arr) => [
        ...arr,
        { label: "description", message: "La descripción es obligatoria" },
      ]);
      validateStatus = false;
    }
    return validateStatus;
  };

  // form submit
  const handleCreate = async (e) => {
    e.preventDefault();
    setErrors([]);
    if (!locked) {
      setLocked(true);
      const validateCheck = validate();
      if (validateCheck) {
        const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
        const tempPayCheck = {
          amount: payCheck.amount,
          description: payCheck.description,
          createdate: formatISO(currentDate),
          employeeId: authenticatedUser.employee_id,
          filialId: filial,
        };
        if (showButton) {
          await addPaycheck(tempPayCheck)
            .then((resp) => {
              displayAlert(
                alerts.SUCCESS,
                "Pago de nómina registrado con éxito"
              );
              updatePaychecks(resp);
              setLocked(false);
              handleClose();
            })
            .catch((err) => {
              setLocked(false);
              displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
            });
        } else {
          await actions
            .addPayCheck(tempPayCheck)
            .then((resp) => {
              // updatePaychecks({
              //   ...tempPayCheck,
              //   employee_name: authenticatedUser.employee_name,
              //   employee_lastname: authenticatedUser.employee_lastname,
              // });
              displayAlert(
                alerts.SUCCESS,
                "Pago de nómina registrado con éxito"
              );
              setLocked(false);
              handleClose();
            })
            .catch((err) => {
              displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
              setLocked(false);
            });
        }
      }
      setLocked(false);
    }
  };

  return (
    <React.Fragment>
      {showButton === true ? (
        <Button variant="contained" color="success" size="small" onClick={() => setOpen(true)}>
          + nómina
        </Button>
      ) : (
        ""
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container alignItems={"center"}>
            <Grid size={10} align="left">
              <Typography variant="h5" align="left">
                <Box sx={{ fontWeight: "bold" }}>Agregar pago de nómina</Box>
              </Typography>
            </Grid>
            <Grid size={2} align="right">
              <IconButton onClick={() => handleClose()}>
                <CloseIcon fontSize="medium" color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            name="amount"
            label="Cantidad en efectivo"
            type="number"
            variant="outlined"
            fullWidth
            autoFocus
            onChange={handleInputchange}
            required
            slots={{
              startAdornment: (
                <InputAdornment position="start" sx={{ paddingRight: "5px" }}>
                  $
                </InputAdornment>
              ),
            }}
            disabled={locked}
            error={checkForErrors("amount") ? true : false}
            helperText={checkForErrors("amount")}
          />

          <TextField
            name="description"
            label="comentarios del pago de nómina"
            type="text"
            fullWidth
            onChange={handleInputchange}
            required
            multiline
            rows={5}
            margin="normal"
            slotProps={{ shrink: true }}
            disabled={locked}
            error={checkForErrors("description") ? true : false}
            helperText={checkForErrors("description")}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            buttonTrigger={handleCreate}
            locked={locked}
            variant="contained"
            color="success"
            buttonText="Enviar"
          />
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            disabled={locked}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default NewPayCheck;
