// Utility import
import React, { Fragment, useState, useContext } from "react";
import { RiSearch2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../context";

// Component import
// import EditEmployee from "./EditEmployee";
import NewEditEmployee from "../popups/NewEditEmployee";
import TableEmployees from "../TableEmployees";
import Loading from "./Loading";
import { Grid2 as Grid } from "@mui/material";
import { ItemPaper } from "../Helper/MUIStyledComponents";

const ListEmployeeMatrix = ({ dataList, headersList }) => {
  const { actions, currentRole } = useContext(NovaContext);
  const [lockStatus, setLockStatus] = useState(false);
  let navigate = useNavigate();

  const handleView = (Id) => {
    const path = `Employees/${Id}`;
    navigate(path);
  };

  return (
    // <Fragment>
    //     <div className="page-container">
    <Grid container>
      <div
        className={`over-page-loading ${
          lockStatus ? "show-loading" : "hide-loading"
        }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${
              lockStatus ? "show-loading" : "hide-loading"
            }`}
          />
        </div>
      </div>
      <Grid container>
        <Grid size={12}>
          <ItemPaper>
            <Grid container spacing={2} align="left">
              <Grid size={12}>
                <NewEditEmployee
                  setLockStatus={setLockStatus}
                  buttonClass={'btn btn-icon w-auto btn-clean"'}
                />
              </Grid>
              <Grid size={12}>
                <TableEmployees
                  dataList={dataList}
                  headersList={headersList}
                  setLockStatus={setLockStatus}
                />
              </Grid>
            </Grid>
          </ItemPaper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListEmployeeMatrix;
