import { Link } from "react-router-dom";

// date-fns
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

//internal
import AmountDisplay from "../../../common/AmountDisplay";
import { checkAccess } from "../../GlobalHelpers";
import { differenceInDays } from "date-fns";
import TooltipIconButton from "../../../controls/TooltipIconButton";

export const prepareExpenseColumns = (
  openConfirmation,
  setSelectedExpense,
  currentRole
) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  const columns = [
    {
      field: "createdate",
      headerName: "Fecha",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 170,
      maxWidth: 250,
      hideable: false,
      sortable: true, 
      valueFormatter: ( value ) => `${format(parseISO(value), "dd/MM/yyyy HH:mm")} hrs`, 
      //sortComparator: (v1, v2) => new Date(v1.createdate) - new Date(v2.createdate), // Comparing date values 
      },
    {
      field: "description",
      headerName: "Descripción",
      headerClassName: "GridHeader",
      align: "left",
      flex: 1,
      minWidth: 150,
      //   width: 400,
      hideable: false,
    },
    {
      field: "employee_name",
      headerName: "RESPONSABLE",
      flex: 1,
      minWidth: 150,
      maxWidth: 500,
      // hideable: false,
      renderCell: (params) => {
        if (hasAuditorAccess || hasRegionalManagerAccess)
          return (
            <Link to={`/Employees/${params?.row.employeeid}`}>
              {params?.row.employee_fullname}
            </Link>
          );
        return params?.row.employee_fullname;
      },
    },
    {
      field: "amount",
      headerName: "Cantidad",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      minWidth: 85,
      maxWidth: 120,
      renderCell: (params) => ( <AmountDisplay amount={params?.row.amount} />),
      
  },

    {
      field: "accionRevocar",
      headerName: "",
      width: 45,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        const triggerConfirmationPage = (e) => {
          e.stopPropagation();
          setSelectedExpense(params.row.id);
          openConfirmation(true);
        };
        const isDisabled =
          differenceInDays(new Date(), parseISO(params?.row.createdate)) > 15;

        return (
          <TooltipIconButton
            title="Rechazar pago"
            onClick={triggerConfirmationPage}
            disabled={isDisabled}
            color="warning"
            size="medium"
            variant="contained"
            icon={<DeleteForeverRoundedIcon fontSize="large" />}
          />
        );
      },
    },
  ];

  return columns;
};

export const columnVisibilityModelExpensesFilialPage = (currentRole) => {
  return {
    employee_name: false,
    accionRevocar: false,
  };
};

export const columnVisibilityModelExpensesContainer = (currentRole) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  return {
    accionRevocar: hasAuditorAccess || hasRegionalManagerAccess,
  };
};
